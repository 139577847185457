import React, { Component } from "react";
import Axios from "axios";
import { filter, has, sortBy } from "lodash";

class Rosters extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    theRosters: undefined,
    theOffense: false,
    theDefense: false,
    theSpecialTeams: false,
    expandedOffense: false,
    expandedDefense: false,
    expandedSpecialTeams: false,
  };

  async componentDidMount() {
    Axios.get(`/team-roster.json`).then((result) => {
      if (!result.data || result.status != "200") return;
      const checkingData = result.data;
      if (typeof checkingData == "object") {
        this.loadAllRosters(checkingData);
      }
    });
  }

  constructTheRosterKeys(theName, el) {
    let theOffenceFiltered = filter(el["athletes"], (o) => {
      return o.position == theName;
    })[0];

    let theOffenceConstructed = {};

    theOffenceFiltered.items.forEach((element) => {
      const posName = element.position.name;
      if (!has(theOffenceConstructed, element.position.name)) {
        theOffenceConstructed[element.position.name] = [];
      }
      theOffenceConstructed[posName].push(element);
    });

    Object.keys(theOffenceConstructed).forEach((element, k) => {
      let updateTheSort = theOffenceConstructed[element];

      updateTheSort = sortBy(updateTheSort, [
        (o) => {
          return o.status.type != "active";
        },
      ]);
      theOffenceConstructed[element] = updateTheSort;
    });

    return theOffenceConstructed;
  }

  loadAllRosters(el) {
    let buildTheOffense = this.constructTheRosterKeys("offense", el);
    let sorttheOffense = Object.keys(buildTheOffense)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = buildTheOffense[key];
        return accumulator;
      }, {});

    let preferredOrderOffense = [
      "Quarterback",
      "Running Back",
      "Wide Receiver",
      "Tight End",
      "Offensive Tackle",
      "Center",
      "Guard",
      "Fullback",
    ];

    const finalOrderOffense = {};

    preferredOrderOffense.forEach((preferredOrder) => {
      const makeKey = String(preferredOrder);
      finalOrderOffense[`${makeKey}`] = sorttheOffense[preferredOrder];
    });

    this.setState({
      theOffense: finalOrderOffense,
    });

    const buildTheDefense = this.constructTheRosterKeys("defense", el);

    let sorttheDefense = Object.keys(buildTheDefense)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = buildTheDefense[key];
        return accumulator;
      }, {});

    let preferredOrderDefense = [
      "Defensive End",
      "Defensive Tackle",
      "Linebacker",
      "Safety",
      "Cornerback",
    ];

    const finalOrderDefense = {};

    preferredOrderDefense.forEach((preferredOrder) => {
      const makeKey = String(preferredOrder);
      finalOrderDefense[`${makeKey}`] = sorttheDefense[preferredOrder];
    });

    this.setState({
      theDefense: finalOrderDefense,
    });
  }

  renderStatus(e, owe = false) {
    if (owe) {
      return "status status-hurt";
    }

    if (e.type == "day-to-day") {
      return "status status-day-to-day";
    }
    if (e.type == "practice-squad") {
      return "status status-practice-squad";
    }
    return "status status-active";
  }

  renderThePlayer(item, k) {
    return (
      <div data-player-reference={item.id} className={`li-data`} key={k}>
        <div
          className={this.renderStatus(item.status, item?.injuries[0]?.status)}
        >
          <div className="img">
            <a href="/roster">
              {item?.headshot?.href ? <img src={item?.headshot?.href} /> : ``}
            </a>
          </div>
          <div className="info">
            <div className="name">{item.fullName}</div>
            <div className="years">
              Experience:{" "}
              {item.experience.years < 1 ? "Rookie" : item.experience.years}
            </div>
            <div className="height">Height: {item.displayHeight}</div>
            <div className="weight">Weight: {item.displayWeight}</div>
          </div>
          <div className="bottom">
            <span className="statuses">
              {item?.injuries[0]?.status
                ? item?.injuries[0]?.status
                : item.status.name}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { theOffense, theDefense } = this.state;

    if (!theOffense || !theDefense) return;

    let availableKeys = [
      ...Object.keys(theOffense),
      ...Object.keys(theDefense),
    ];

    // remove Fullback
    availableKeys.splice(7, 1);

    const targetedDisplay =
      availableKeys[Math.floor(Math.random() * availableKeys.length)];

    const targetedGroup = theDefense.hasOwnProperty(targetedDisplay)
      ? theDefense[targetedDisplay]
      : theOffense[targetedDisplay];

    return (
      <div id="rosterblock" className="roster-block roster-card-block">
        <div className="the-body">
          {/* - - - - - - - - - - */}
          <div className="the-defense the-outside-grouping">
            {
              <div className="grouping-container">
                <div className="group-title">
                  <h4> 49ers Team Roster for {targetedDisplay} </h4>
                </div>
                <div className="subgroup">
                  {targetedGroup.map((item, k) => {
                    return (
                      <div className="player-container" key={k}>
                        {this.renderThePlayer(item, k)}
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          </div>
        </div>
        <div className="link-bottom">
          <a href="/roster">
            View full rosters for the team{" "}
            <i className="fa-regular fa-hand-point-right"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Rosters;
