import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import News from "../components/News.js";
import LeadersBlock from "../components/LeadersBlock.js";
import RostersBlock from "../components/RostersBlock.js";

const IndexPage = () => {
  return (
    <>
      <Head
        title="Latest News"
        description="SF 49ers latest news for the fans across the world and statistics."
      />
      <Layout>
        <main>
          <div className="main-wrapper news-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main">
                  <News />
                </div>
                <div className="right">
                  {/* + + */}

                  <LeadersBlock />

                  {/* + + */}

                  <RostersBlock />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
