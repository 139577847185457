import React, { Component } from "react";
import Axios from "axios";
import { orderBy, filter, uniqBy } from "lodash";
import moment from "moment";
class News extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoading: true,
    theData: undefined,
  };

  async componentDidMount() {
    Axios.get(`/articles.json`).then((result) => {
      if (!result.data) return;

      const checkingData = result.data;
      if (typeof checkingData == "object" && checkingData.length > 5) {
        this.loadTheData(checkingData);
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 250);
      }
    });
  }

  loadTheData(el) {
    const mrSort = orderBy(el, ["publishedAt"], ["desc"]);
    const properData = mrSort.map((e) => {
      const someNew = e;
      const friendlyDate = moment(someNew.publishedAt).format("MMMM Do YYYY");
      someNew["friendlyDate"] = friendlyDate;
      return someNew;
    });

    // ///// ///// ///// ///
    let scrubbing = filter(properData, (o) => {
      return o.urlToImage;
    });

    scrubbing = uniqBy(scrubbing, "title");
    scrubbing = uniqBy(scrubbing, "publishedAt");
    scrubbing = uniqBy(scrubbing, "url");
    scrubbing = uniqBy(scrubbing, "content");
    scrubbing = uniqBy(scrubbing, "description");

    // - ------ -----

    if (this.props?.short) {
      const slicedArray = scrubbing.slice(0, 19);
      this.setState({ theData: slicedArray });
    } else {
      this.setState({ theData: scrubbing });
    }
  }

  render() {
    const { theData, isLoading } = this.state;

    if (isLoading || !theData) {
      return (
        <div className="universal-spinner">
          <i className="fa fa-cog fa-spin" />
        </div>
      );
    }

    //
    return (
      <div id="news" className="news common-grid">
        <div className="top">
          <h2>Latest San Francisco 49ers News</h2>
        </div>
        <div className="the-body">
          <div className="gridder">
            <div className="max-gridder-height">
              {theData.map((node, i) => {
                return (
                  <div
                    className={`element-data element-data-type--${node.type}`}
                    key={i}
                  >
                    <div className="urlToImage">
                      <a href={node.url} target="_blank">
                        <img src={node.urlToImage} />
                      </a>
                    </div>
                    <div className="content">
                      <div className="title">{node.title}</div>
                      <div className="friendlyDate">{node.friendlyDate}</div>
                      <div className="description">
                        {node.description.replace(/(<([^>]+)>)/gi, "")} &nbsp;
                        <a href={node.url} target="_blank">
                          full article
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="bottom"></div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default News;
