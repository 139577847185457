import React, { Component } from "react";
import Axios from "axios";
import { filter } from "lodash";

class LeadersBlock extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    theData: undefined,
    theLeaders: undefined,
  };

  async componentDidMount() {
    Axios.get(`/team-roster.json`)
      .then((result) => {
        if (!result.data || result.status != "200") return;
        const checkingData = result.data;
        if (typeof checkingData == "object") {
          this.loadTheRoster(checkingData);
        }
      })
      .then(() => {
        Axios.get(`/team-stats.json`).then((result) => {
          if (!result.data || result.status != "200") return;

          const checkingData = result.data;
          if (typeof checkingData == "object") {
            this.loadTheLeaders(checkingData);
          }
        });
      });
  }

  loadTheLeaders(el) {
    if (!el.categories) return;

    const kingStatics = el;

    kingStatics.categories.forEach((element, key) => {
      element.leaders.forEach((eachOneEl, i) => {
        const playerMarker = eachOneEl.athlete["$ref"];
        const grabTheId = playerMarker.slice(
          playerMarker.lastIndexOf("athletes/") + 9,
          playerMarker.lastIndexOf("?lang=en&region=us")
        );

        const matchedTheGuy = filter(this.state.theData, (o) => {
          return o.id == grabTheId;
        })[0];

        if (
          matchedTheGuy &&
          matchedTheGuy.guid &&
          kingStatics.categories[key].leaders[i]
        ) {
          kingStatics.categories[key].leaders[i]["myAthleteInformation"] =
            matchedTheGuy;
        }
      });

      kingStatics.categories[key].leaders = filter(
        kingStatics.categories[key].leaders,
        (o, i) => {
          return o["myAthleteInformation"] && i < 3;
        }
      );
    });

    // console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀", kingStatics.categories);

    this.setState({ theLeaders: kingStatics.categories });
  }

  loadTheRoster(el) {
    const combined = [];

    el["athletes"].forEach((element) => {
      element.items.forEach((eachOneEl) => {
        const addPlayer = eachOneEl;
        addPlayer["which-side"] = element.position;
        combined.push(addPlayer);
      });
    });

    this.setState({ theData: combined });
  }

  renderSummary(node) {
    return node["leaders"].map((subnode, k) => {
      if (k > 0) {
        return null;
      }
      return (
        <div className="person-group" key={k}>
          <div className="categ">{node.displayName}</div>

          {subnode.hasOwnProperty("myAthleteInformation") ? (
            <div className="person">
              <div className="img">
                <a href="/statistics">
                  <img src={subnode["myAthleteInformation"].headshot.href} />
                </a>
              </div>

              <div className="content">
                <div className="name">
                  <a href="/statistics">
                    {subnode["myAthleteInformation"].displayName}
                  </a>
                </div>

                <div className="displayvalue">
                  {subnode["displayValue"]}{" "}
                  <span>
                    {["PYDS", "RYDS", "RECYDS"].includes(node.abbreviation)
                      ? " "
                      : node.displayName}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  }

  render() {
    const theData = this.state.theData;
    const theLeaders = this.state.theLeaders;

    if (!theData || !theLeaders) return;

    // console.log("🚀 ---->>>>>>> >>>>>>>>>> >>>>>>>>>> ", theLeaders);

    return (
      <div id="statisticsblock" className="statisticsblock">
        <div className="top">
          <h4>Team Leaders</h4>
        </div>

        <div>
          {theLeaders ? (
            <div>
              <div className="item">{this.renderSummary(theLeaders[7])} </div>
              <div className="item">{this.renderSummary(theLeaders[0])} </div>
              <div className="item">{this.renderSummary(theLeaders[8])} </div>
              <div className="item"> {this.renderSummary(theLeaders[2])}</div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="link-bottom">
          <a href="/statistics">
            View statistics leaders for each category{" "}
            <i className="fa-solid fa-angles-right"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default LeadersBlock;
